.top-bar {
  display: flex;
  justify-content: flex-end;
  //   background: #ffffff;
  //   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  .userHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
    .userImage {
      border-radius: 50%;
      width: 50px;
    }
    p {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #002449;
      display: flex;
      align-items: center;
      margin-bottom: 0px;
    }
  }
}

// .search-icon {
//     padding: 20px 30px 0 20px;
// }

.man-icon {
  border-radius: 20px;
  margin: 20px 30px 0 20px;
}

.flex {
  display: flex;
}

.username-text {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  color: #000000;
  position: relative;
  width: 72px;
  height: 23px;
  top: 33px;
  right: 20px;
}

.down-caret {
  height: 5px;
  position: relative;
  top: 43px;
  right: 10px;
}

.logo-container {
  height: 49px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: 20px;
  width: 53%;
  padding: 15px 0;
  text-align: center;
  // background: #ffffff;
}
.dropdown {
  #drop {
    position: absolute !important;
    transform: translate(-10px, 20px) !important;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
  }
  #dropdown-basic {
    width: 100%;
    box-shadow: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: transparent;
    font-size: 0.85rem;
    line-height: 21px;
    font-family: "Poppins";
    color: black;
    padding: 0px !important;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:after {
      display: none;
    }
  }
  .dropText {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #444444;
    opacity: 0.5;
  }
}
