$primary-color: #243d63;
$primary-color-second: #1b2e6b;
$button-color: #0e69c2;
$table-header: #4676bf;
$table-text: #545454;
$white: #fff;
$black: #000000;
$secondary-gray: #7c7c7c;
$black-1c: #1c2c25;
$grey: #444444;
$grey-65: #696464;
$black-4D: #4d4d4d;
$full-height: 100vh;

.text-black {
  color: $black;
}

.primaryBlue{
  color:$button-color;
}

.text-black-30 {
  color: #303030;
}

.text-grey {
  color: $grey;
}

.text-black-4D {
  color: $black-4D;
}

.text-grey-65 {
  color: $grey-65;
}

.cursor-pointer {
  cursor: pointer;
}
.bg-white {
  background: $white;
}
.m-0{
  margin: 0 !important;
}
.txt-right{
  text-align: right;
}
.d-flex{
  display: flex;
}
.space-btw{
  justify-content: space-between;
}
